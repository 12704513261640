@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  background: #fff;
}
@media screen and (max-width: 450px) {
  html,
  body {
    font-size: calc(1.9vh) !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
  background: #ddd;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
  background-color: #999;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*  ---------- BUTTON START -------------- */

.charge-start-button-inActive {
  position: relative;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background-color: #feb211;
  border: none;
  cursor: pointer;
}

.charge-start-button-container-inActive {
  border-radius: 50%;
  width: 11.5em;
  height: 11.5em;
  border: 8px solid #ff9153;
}

.charge-start-button-container {
  border-radius: 50%;
  width: 11.5em;
  height: 11.5em;
  animation: shadow-pulse 1s infinite;
  border: 8px solid #ff9153;
}

.charge-start-button {
  position: relative;
  width: 8em;
  height: 8em;
  border-radius: 50%;
  background-color: #feb211;
  border: none;
  cursor: pointer;
}

.charge-start-button span {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 16px;
}

.charge-start-button::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  box-shadow: 0 0 10px #a76f08, 0 0 20px #a76f08, 0 0 40px #a76f08,
    0 0 80px #a76f08, 0 0 160px #a76f08;
  opacity: 0.5;
  animation: charge 2s infinite ease-in-out;
}

.charge-start-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33;
  opacity: 0.5;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes charge {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33;
  }
  25% {
    box-shadow: 0 0 0 10px #ffcc33, 0 0 0 20px #ffcc33, 0 0 0 30px #ffcc33;
  }
  50% {
    box-shadow: 0 0 0 30px #ffcc33, 0 0 0 20px #ffcc33, 0 0 0 10px #ffcc33;
  }
  75% {
    box-shadow: 0 0 0 20px #ffcc33, 0 0 0 10px #ffcc33, 0 0 0 30px #ffcc33;
  }
  100% {
    box-shadow: 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33, 0 0 0 0 #ffcc33;
  }
}

/*  ---------- BUTTON STOP -------------- */
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(189, 155, 25, 0.5);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
  }
}

@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(189, 155, 25, 0.5);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(0, 0, 0, 0);
  }
}

.charging-container-inActive {
  border-radius: 50%;
  width: 11.5em;
  height: 11.5em;
  border: 8px solid #ff9153;
}

.charging-inActive {
  float: left;
  width: 8em;
  height: 8em;
  text-transform: uppercase;

  font-size: 1.2em;
  letter-spacing: 1px;
  color: #6b706e;
  text-align: center;
  background: linear-gradient(270.09deg, #feb310 24.35%, #ff9153 99.93%);
  border-radius: 50%;
  font-weight: 700;
  user-select: none;
}

.charging-container {
  border-radius: 50%;
  width: 11.5em;
  height: 11.5em;
  animation: shadow-pulse 1s infinite;
  border: 8px solid #ff9153;
}

.charging {
  float: left;
  width: 8em;
  height: 8em;
  text-transform: uppercase;

  font-size: 1.2em;
  letter-spacing: 1px;
  color: #6b706e;
  text-align: center;
  background: linear-gradient(270.09deg, #feb310 24.35%, #ff9153 99.93%);
  border-radius: 50%;
  animation: shadow-pulse 1s infinite;
  font-weight: 700;
  user-select: none;
}

.charging:active {
  animation: shadow-pulse-big 1s infinite;
}
